import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";

// 引入公共样式
import "@/assets/style/common.css";
import "element-ui/lib/theme-chalk/index.css";

import "@/utils/rem";

import echarts from "@/plugin/EChart";
Vue.prototype.$echart = echarts;

Vue.use(ElementUI);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
