import Vue from "vue";
import VueRouter from "vue-router";
import {storageAPI} from "@/utils/common";

Vue.use(VueRouter);

// 动态创建路由
const creatRouter = (type) => {
  let menus = JSON.parse(storageAPI.get('menus')) || []
  menus.forEach((item) => {
    let routerObj = {};
    routerObj['path'] = item;
    routerObj['name'] = item;
    routerObj['component'] = () => import(`@/views/system/${item}/index`);
    router.addRoute("system", routerObj)
  })
}

const routes = [
  {
    path: "/",
    redirect: "/index",
  },
  {
    path: "/index",
    name: "index",
    component: () => import("@/views/Index/Index"),
  },
  {
    path: "/agent",
    name: "agent",
    component: () => import("@/views/Agent/Agent"),
  },
  {
    path: "/doc",
    name: "doc",
    component: () => import("@/views/Doc/Doc"),
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/Login/Login"),
  },
  {
    path: "/register",
    name: "register",
    component: () => import("@/views/Register/Register"),
  },
  {
    path: "/agreement",
    name: "agreement",
    component: () => import("@/views/Agreement/Agreement"),
  },
  {
    path: "/system",
    name: "system",
    redirect: "/system/draw_ip",
    component: () => import("@/views/system/system"),
  },
  {
    path: "/404",
    name: "notFound",
    component: () => import("@/views/404/index")
  },
  {
    path: "*",
    redirect: "/index",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

creatRouter()

export { creatRouter }
router.beforeEach((to, from, next) => {
  let menus = JSON.parse(storageAPI.get('menus'))
  if (to.path == '/system/admin' && !menus.includes('admin')) {
    next('404')
  }
  next()
})
export default router;
