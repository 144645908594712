const storageAPI = {
  // 操作localStorage(存储)
  localStorage: window.localStorage,

  // 操作localStorage(设置)
  set: (item, value) => {
    localStorage.setItem(item, value);
  },

  // 操作localStorage(读取)
  get: (item) => {
    return localStorage.getItem(item);
  },

  // 操作localStorage(删除(可批量))
  remove: (item) => {
    if (Array.isArray(item)) {
      item.forEach((option) => {
        localStorage.removeItem(option);
      });
    } else {
      localStorage.removeItem(item);
    }
  },
};

export { storageAPI };
